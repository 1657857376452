import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import CopyRight from "../components/copyright"
import Bio from '../components/Bio'
import SocialList from "../components/socialList"
import { rhythm } from '../utils/typography'

class ContactPage extends React.Component {
  render() {
    // get props
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')

    return (
      <Layout location={this.props.location}>

        <Helmet>
          <meta name="language" content="en" />
          <title>Contact - {siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <header>
          <h1 style={{ color: 'dodgerblue' }}>Contact</h1>
          <p>Please fill out the form below to send us email. We will get back to you ASAP.</p>
        </header>

        <hr />

        <main>
          <article>
            
             <p>[form goes here]</p>

            <SocialList />
          </article>
        </main>

        <hr style={{ marginBottom: rhythm(1) }} />

       <Bio />
       <CopyRight />
      </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`